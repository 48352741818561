<template>
  <div class="public-home">
    <AppHero />


    <div class="mt-5">
      <b-overlay :show="loading">
        <div class="job-search">
          <public-jobs-display />
        </div>
        
        <div class="candidates-section bg-primary py-4">
          <h4 class="text-center mb-2 text-white">OUR CANDIDATES</h4>
          <swiper
            class="swiper-coverflow"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide
              v-for="(candidate, index) in candidates"
              :key="index"
              class="candidate-slider w-25"
            >
              <b-card no-body class="candidate-wrapper" style="overflow: hidden;">
                <b-row>
                  <b-col md="5" sm="8">
                    <b-avatar
                      :src="candidate.avatar"
                      :text="avatarText(candidate.name)"
                      :variant="`light-primary`"
                      size="120px"
                      rounded
                    />
                  </b-col>
                  <b-col>
                    <div>
                      <span>Name</span>
                      <h4 class="mb-50">{{ candidate.name }}</h4>

                      <span>Country</span>
                      <h4 class="mb-50">{{ candidate.country }}</h4>

                      <span>Region/State/Province</span>
                      <h4 class="mb-50">{{ candidate.region }}</h4>
                    </div>
                  </b-col>
                </b-row>

                <div class="mr-1 mt-1 mb-50 d-flex align-items-center justify-content-between">
                  <div md="3">
                    <h5 class="font-weight-normal">Gender</h5>
                  </div>

                  <div md="9">
                    <b-badge variant="primary" class="text-capitalize text-lg">{{ candidate.gender }}</b-badge>
                  </div>
                </div>

                <div class="mr-1 mb-50 d-flex align-items-center justify-content-between">
                  <div md="3">
                    <h5 class="font-weight-normal">Number of schools attended</h5>
                  </div>

                  <div md="9">
                    <b-badge variant="primary" class="text-capitalize text-lg">{{ candidate.education_history }}</b-badge>
                  </div>
                </div>

                <div class="mr-1 mb-50 d-flex align-items-center justify-content-between">
                  <div md="3">
                    <h5 class="font-weight-normal">Number of employments</h5>
                  </div>

                  <div md="9">
                    <b-badge variant="primary" class="text-capitalize text-lg">{{ candidate.work_experience }}</b-badge>
                  </div>
                </div>

                <div class="mr-1 mb-1 d-flex align-items-center justify-content-between">
                  <div md="3">
                    <h5 class="font-weight-normal">Age</h5>
                  </div>

                  <div md="9">
                    <b-badge variant="primary" class="text-capitalize text-lg">{{ candidate.age }}</b-badge>
                  </div>
                </div>

                <div class="">
                  <b-link class="text-lg text-warning d-inline-block mb-1 mt-1" :to="{ name: 'auth-login' }">Login to view CV</b-link>
                  <span class="candidate-status" :class="{ success: !candidate.employed, danger: candidate.employed}">{{ candidate.employed ? 'EMPLOYED' : 'AVAILABLE' }}</span>
                </div>
              </b-card>
            </swiper-slide>

            <div
              slot="pagination"
              class="swiper-pagination"
            />
          </swiper>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BImg,
  BCard,
  BBadge,
  BAvatar,
  BOverlay
} from 'bootstrap-vue';
import { get } from "lodash";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import PublicJobsDisplay from "@core/components/shared/jobs/PublicJobsDisplay.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import AppHero from '@core/components/app-home/AppHero.vue';

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BLink,
    BImg,
    Swiper,
    BCard,
    BBadge,
    BAvatar,
    AppHero,
    SwiperSlide,
    PublicJobsDisplay
  },
  data() {
    return {
      loading: false,
      candidates: [

      ],
      swiperOptions: {
        autoplay: false,
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
    }
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        this.loading = true; 

        const request = await this.useJwt().sharedService.fetchCandidates();
        const { data } = request.data;
        this.candidates = data
        // this.candidates = [...data, ...data, ...data];
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/app-home.scss';
  .candidate-wrapper {
    position: relative;
  
    .text-lg {
      font-size: 13px;
    }
    
    .candidate-status {
      display: block;
      text-transform: uppercase;
      width: 50%;
      color: white;
      font-weight: bold;
      font-size: 14px;
      padding: 5px;
      text-align: center;
      border-radius: 5px;
      position: absolute;
      transform: rotate(-20deg);
      right: -40px;
      bottom: 5px;
      margin-left: auto;
      overflow: hidden;
    }
    .candidate-status.success {
      background-color: darkgreen;
    }
    .candidate-status.warning {
      background-color: darkorange;
    }
    .candidate-status.danger {
      background-color: darkred;
    }
  }

  /* SM breakpoint */
  @media (min-width: 576px) {
    h1 {
      font-size: 16px;
    }
    /* CUSTOM WIDTHS */
    .w-sm-10 { width: 10%!important; }
    .w-sm-15 { width: 15%!important; }
    .w-sm-25 { width: 25%!important; }
  }

  /* MD breakpoint*/
  @media (min-width: 768px) {
    /* CUSTOM WIDTHS */
    .w-md-10 { width: 10%!important; }
    .w-md-15 { width: 15%!important; }
    .w-md-25 { width: 25%!important; }
  }

  /* LG breakpoint */
  @media (min-width: 992px) {
    /* CUSTOM WIDTHS */
    .w-lg-10 { width: 10%!important; }
    .w-lg-15 { width: 15%!important; }
    .w-lg-25 { width: 25%!important; }
  }
</style>

